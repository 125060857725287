import React , {useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Container, Card, Form, Button, ListGroup, InputGroup, OverlayTrigger, Popover, Tooltip } from 'react-bootstrap'
import Message from '../components/Message'
import Loader from '../components/Loader'
import Meta from '../components/Meta'
import FormContainer from '../components/FormContainer'

import SmartLearnCard from '../components/smartlearn/SmartLearnCard'
import SmartLearnQuestion from '../components/smartlearn/SmartLearnQuestion'
import SearchConcepts from '../components/smartlearn/SearchConcepts'
import SmartLearnCardOne from '../components/smartlearn/SmartLearnCardOne'


import DoubtInput from '../components/smartlearn/DoubtInput'


import {listRecentTopics, generateSmartLearnCard, generateSmartLearnCardUrl} from '../actions/smartlearnActions.js'

import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile,
    deviceDetect
  } from "react-device-detect";


export const SmartLearnScreen = ({match, history}) => {

    var concept_url = match.params.concept_url

    const [selectedTopic, setSelectedTopic ] = useState('')

    const [textQuery, setTextQuery ] = useState('')

    const [viewMode, setViewMode] = useState('topics') //learningpath

    const [keyword, setKeyword] = useState('')


    const [mobileViewToggle, setMobileViewToggle] = useState('session')

    const dispatch = useDispatch()


    const sltopicList = useSelector(state => state.sltopicList)
    const { sltopics:topics } = sltopicList

    const learningPath = useSelector(state => state.learningPath)
    const { learningpath } = learningPath

    const slcardList = useSelector(state => state.slcardList)
    const { slcards } = slcardList

    const slconceptnav = useSelector(state => state.slconceptnav)
    const { conceptnav } = slconceptnav


    const popover = (
        <Tooltip id="popover-basic">
            Learning
        </Tooltip>
    );


    
    const submitHandler = (e) => {
        e.preventDefault()

        
        dispatch(generateSmartLearnCard(textQuery, 'concept'))

    }

    const selectTopic = (topic_in) => {

        //setSelectedTopic(topic_in)

    }

    const selectQuestion = (question_in) => {
        
        setTextQuery(question_in)
        setSelectedTopic(question_in)

        dispatch(generateSmartLearnCard(question_in, 'concept'))

    }


    useEffect(() => {


        dispatch(listRecentTopics())

        if(concept_url){
            dispatch(generateSmartLearnCardUrl(concept_url))
        }

       




    }, [])




    return (

        <>
            <Meta title='TY Smart Doubt Tool' description='The Smart Doubt tool is a prototype learning model that makes it extremely easy and interactive for students to clear the doubts and learn. Currently features topcs in Maths and English Grammar. It is a perfect starting point for an AI integrated doubt solving system.' keywords='teachyaar, doubt solver, fun learning, AI technologies, smart learn, learn concepts, learn maths, learn english'/>

            {/* <p className='h3' style={{textAlign:'center'}}>Smart Learn</p> */}

            {isMobile && (

                <>


                <div style={{display:'flex'}}>

                


                {mobileViewToggle == 'topics' && (
                        <div style={{overflow:'scroll', width:'99%'}}>

                <InputGroup>
                    <Button style={{margin:'5px', borderRadius:'20px', backgroundColor:'#c2179a'}} onClick={()=>setMobileViewToggle('topics')}>Topics</Button>
                    <Button style={{margin:'5px', borderRadius:'20px', backgroundColor:'#c2179a'}} onClick={()=>setMobileViewToggle('session')}>Session</Button>
                </InputGroup>


                        <p className='h5' style={{textAlign:'center', border:'None', backgroundColor:'#F2EFEF', padding:'10px'}}>Topics</p>

                        <SearchConcepts />

                        <ListGroup>
                            {topics && topics.map((topic, index)=>(
                                <ListGroup.Item onClick={()=>{selectQuestion(topic.hashtag); setMobileViewToggle('session')}} >
                                    {topic.title}
                                </ListGroup.Item>
                            ))}
                            
                        </ListGroup>
                    </div>
                
                )}


                {mobileViewToggle == 'session' && (
                    
                    <div style={{display:'flex', flexDirection: 'column-reverse', overflow:'scroll',width:'99%'}} >


                <InputGroup>
                    <Button style={{margin:'5px', borderRadius:'20px', backgroundColor:'#c2179a'}} onClick={()=>setMobileViewToggle('topics')}>Topics</Button>
                    <Button style={{margin:'5px', borderRadius:'20px', backgroundColor:'#c2179a'}} onClick={()=>setMobileViewToggle('session')}>Session</Button>
                </InputGroup>

                <DoubtInput />


                    {/* <p className='h5' style={{textAlign:'center', border:'None', backgroundColor:'#F2EFEF', padding:'10px'}}>Start your learning session. Pick a topic from the topic list</p> */}

                    {conceptnav && (
                    <SmartLearnQuestion question={conceptnav} />

                    )}


                    
                    {slcards && slcards.map((slcard, index)=>(
                        <SmartLearnCard index={index} slcard={slcard}/>
                    ))}

                    <SmartLearnCardOne />





                    </div>



                )}


                

                </div>

                </>

            )}




            {!isMobile && (



        
            <div className='body'>

                <div className='sidenav'>

                        <>

                            <p className='h5' style={{textAlign:'center', border:'None', backgroundColor:'#F2EFEF', padding:'10px'}}>Topics</p>

                            <SearchConcepts />

                            <ListGroup>
                                {topics && topics.map((topic, index)=>(
                                    <ListGroup.Item onClick={()=>selectQuestion(topic.hashtag)}>
                                        {topic.title}
                                    </ListGroup.Item>
                                ))}
                                
                            </ListGroup>
                        </>



                </div>

                <div className='content' style={{display:'flex', flexDirection: 'column-reverse'}} >


                <DoubtInput />

                {/* <Card>
                    <Card.Header style={{border:'solid'}}> */}
                        {/* <p className='h5' style={{textAlign:'center', border:'None', backgroundColor:'#F2EFEF', padding:'10px'}}>Start your learning session. Pick a topic from the topic list</p> */}
                    {/* </Card.Header>
                </Card> */}


                {conceptnav && (
                <SmartLearnQuestion question={conceptnav} />

                )}


                
                {slcards && slcards.map((slcard, index)=>(
                    <SmartLearnCard index={index} slcard={slcard}/>
                ))}

                <SmartLearnCardOne />





                {/* <Button className='smartlearnbuttons' style={{margin:'5px'}}>Reset Session</Button> */}


                


                </div>


            </div>


            
            )}


                
  

        </>
    )
}


export default SmartLearnScreen
