import axios from 'axios';
import {

    LOAD_QUIZLIST,
    LOAD_QUIZLIST_ERROR,
    LOAD_QUIZ_BY_ID,
    LOAD_QUIZ_ERROR,
    CHECK_SOLUTION

} from '../constants/quizConstants'



export const QuizListReducer = (state = {topics: []}, action) => {

    switch(action.type){

        case LOAD_QUIZLIST:
            return { loading: false, topics: action.payload }

        case LOAD_QUIZLIST_ERROR:
            return { loading: false, error: action.payload, topics:state.topics }

        default:
            return state
    }


}



export const QuizDetailReducer = (state = {title:'', questions:[], score:0, attempted:0, concept:null, pageSEO:null, nextQuiz:null, passage:{}, instructions:[], instruction:null}, action) => {

    switch(action.type){

        case LOAD_QUIZ_BY_ID:
            return { loading: false, title: action.payload.title, questions:action.payload.questions,score:0, attempted:0, concept:action.payload.concept, pageSEO:action.payload.pageSEO, nextQuiz:action.payload.nextQuiz, passage:action.payload.passage, instructions:action.payload.instructions, instruction:action.payload.instruction  }

        case LOAD_QUIZ_ERROR:
            return { loading: false, error: action.payload, title: state.title, questions:state.questions,score:state.score, attempted:state.attempted, concept:state.concept,pageSEO:state.pageSEO, nextQuiz:state.nextQuiz, instructions:state.instructions, instruction:state.instruction  }

        case CHECK_SOLUTION:
            return { loading: false,  
                title: state.title, 
                questions: state.questions.map(qs => qs._id === action.payload.question_id ? { ...qs, answer:action.payload.answer, correct:action.payload.ans, isAnswered:true, learn:action.payload.learn}:qs),
                score: action.payload.ans == 'Correct' ? state.score + 1 : state.score,
                attempted:state.attempted + 1,
                concept:state.concept,
                pageSEO:state.pageSEO,
                nextQuiz: state.nextQuiz,
                passage: state.passage,
                instructions: state.instructions,
                instruction: state.instruction
            }

        default:
            return state
    }


}