// Author : Anil Variyar
// License : Proprietary
// Property of : Anil Variyar/ Pixagan Technologies Private Limited

import React from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { Container } from 'react-bootstrap'



import PrivateRoute from './components/routing/PrivateRoute'
import AdminRoute from './components/routing/AdminRoute'


import Alert from './components/Alert'
import Header from './components/Header'
import Footer from './components/Footer'


import QuizScreen from './screens/QuizScreen'
import CreateQuizScreen from './screens/CreateQuizScreen'
import LoginScreen from './screens/LoginScreen'
import LogoutScreen from './screens/LogoutScreen'
import LandingScreen from './screens/LandingScreen'
import SessionsScreen from './screens/SessionsScreen'

import TermsScreen from './screens/TermsScreen'
import PrivacyScreen from './screens/PrivacyScreen'

import SmartLearnScreen from './screens/SmartLearnScreen'

const App = () => {
  return (

    <Router>
      <Header/>

      <main className='py-3'>

        <Container fluid>

          <Switch>

          <Route path='/' component={LandingScreen} exact />

          <Route path='/quiz' component={QuizScreen} exact />

          <Route path='quiz/:quiz_id' component={QuizScreen} exact />

          <Route path='/sessions' component={SessionsScreen} exact />

          <Route path='/smartdoubts' component={SmartLearnScreen} exact />
          <Route path='/smartdoubts/:concept_url' component={SmartLearnScreen} exact />

          

          <Route path='/admin3323/login' component={LoginScreen} exact />

          <PrivateRoute path='/admin3323/create' component={CreateQuizScreen} exact />

          <PrivateRoute path='/admin3323/logout' component={LogoutScreen} exact />



          <Route path='/termsandconditions' component={TermsScreen} exact />

          <Route path='/privacypolicy' component={PrivacyScreen} exact />

          <Route component={QuizScreen} />


          </Switch>
        

        </Container>



        
      </main>
      <Footer />
    </Router>
  )
}

export default App


