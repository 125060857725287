export const CREATE_LOAD_QUIZLIST  = 'CREATE_LOAD_QUIZLIST'
export const CREATE_LOAD_QUIZLIST_ERROR  = 'CREATE_LOAD_QUIZLIST_ERROR'

export const CREATE_LOAD_QUIZ_BY_ID  = 'CREATE_LOAD_QUIZ_BY_ID'
export const CREATE_LOAD_QUIZ_ERROR  = 'CREATE_LOAD_QUIZ_ERROR'
export const CREATE_ADD_QUIZ  = 'CREATE_ADD_QUIZ'


export const CREATE_ADD_QUIZ_QUESTION  = 'CREATE_ADD_QUIZ_QUESTION'
export const DELETE_QUIZ_QUESTION  = 'DELETE_QUIZ_QUESTION'
export const EDIT_QUIZ_QUESTION  = 'EDIT_QUIZ_QUESTION'

export const EDIT_QUIZ_CONCEPT  = 'EDIT_QUIZ_CONCEPT'
export const EDIT_QUIZ_INSTRUCTIONS  = 'EDIT_QUIZ_INSTRUCTIONS'
export const EDIT_QUIZ_PASSAGE  = 'EDIT_QUIZ_PASSAGE'
