import React , {useState, useEffect, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Container, Card, Form, Button, ListGroup, OverlayTrigger, Popover, Tooltip, Carousel, InputGroup, FormControl, Table, Radio, Badge } from 'react-bootstrap'

import {updateConcept} from '../../actions/creatorActions'


export const ConceptCardItem = ({quiz_id, item, index}) => {


  const dispatch = useDispatch()

  const [viewMode, setViewMode] = useState('view')
  const [text, setText] = useState('')


  const toggleViewMode = () => {
    if(viewMode == 'view'){
        setViewMode('edit')
    }else{
        setViewMode('view')
    }
  }

  useEffect(() => {


    setText(item)

  }, [item]);


  const updateItemRequest = () => {
    
        dispatch(updateConcept(quiz_id, index, text))
        setViewMode('view')
    
   }



  
  return (

    <>

        {viewMode == 'view' ? (
            <li className='h5'>
                {item}  | <Button onClick={()=>toggleViewMode()}>Edit</Button>
            </li>
        ): (
            <>
                <Form.Control type='text' placeholder='text' value={text} onChange={(e) => setText(e.target.value)}> 
                </Form.Control>
                <Button onClick={()=>updateItemRequest()}>Update</Button>
                <Button onClick={()=>setViewMode('view')}>Close</Button>
            </>
            
        )}
        
    
    </>

    // <Card style={{padding:'0px', borderRadius:'20px', borderColor:'#a516f2', marginBottom:'20px'}}>

        
    //     <Card.Header style={{borderRadius:'20px'}}>
    //         <ul>
    //         {concept.items && concept.items.map((citem, index)=>(

    //             <li className='h5'>{citem}</li>

    //             ))}
    //         </ul>


    //     </Card.Header>

    // </Card>


  );
};

export default ConceptCardItem;
