import axios from 'axios';
import {

    CREATE_LOAD_QUIZLIST,
    CREATE_LOAD_QUIZLIST_ERROR,

    CREATE_LOAD_QUIZ_BY_ID,
    CREATE_LOAD_QUIZ_ERROR,

    CREATE_ADD_QUIZ,

    CREATE_ADD_QUIZ_QUESTION,
    DELETE_QUIZ_QUESTION,
    EDIT_QUIZ_QUESTION,
    EDIT_QUIZ_CONCEPT,
    EDIT_QUIZ_INSTRUCTIONS,
    EDIT_QUIZ_PASSAGE
    


} from '../constants/creatorConstants'



export const CreateQuizListReducer = (state = {quizlist: []}, action) => {

    switch(action.type){

        case CREATE_LOAD_QUIZLIST:
            return { loading: false, quizlist: action.payload }


        case CREATE_ADD_QUIZ:
            return { loading: false, quizlist: [action.payload, ...state.quizlist] }


        case CREATE_LOAD_QUIZLIST_ERROR:
            return { loading: false, error: action.payload, quizlist:state.quizlist }

        default:
            return state
    }


}



export const CreateQuizDetailReducer = (state = {quiz_id:'', title: '', questions:[],score:0, attempted:0, concept:{}, pageSEO:{}, nextQuiz:'', passage:{}, instructions:[], instruction:null}, action) => {

    switch(action.type){

        case CREATE_LOAD_QUIZ_BY_ID:
            return { loading: false, quiz_id:action.payload.quiz_id, title: action.payload.title, questions:action.payload.questions,score:0, attempted:0, concept:action.payload.concept, pageSEO:action.payload.pageSEO, nextQuiz:action.payload.nextQuiz, passage:action.payload.passage, instructions:action.payload.instructions, instruction:action.payload.instruction  }

        case CREATE_LOAD_QUIZ_ERROR:
            return { loading: false, error: action.payload, quiz_id:state.quiz_id, title: state.title, questions:state.questions, score:state.score, attempted:state.attempted, concept:state.concept, pageSEO:state.pageSEO, nextQuiz:state.nextQuiz , instructions:state.instructions, instruction:state.instruction, passage:state.passage}

        case CREATE_ADD_QUIZ_QUESTION:
            return { loading: false, quiz_id:state.quiz_id, title: state.title, questions:[action.payload, ...state.questions],score:state.score, attempted:state.attempted, concept:state.concept, pageSEO:state.pageSEO, nextQuiz:state.nextQuiz, instructions:state.instructions, instruction:state.instruction,passage:state.passage }

        case EDIT_QUIZ_QUESTION:
            return { loading: false, quiz_id:state.quiz_id, title: state.title, questions:[action.payload, ...state.questions],score:state.score, attempted:state.attempted, concept:state.concept, pageSEO:state.pageSEO, nextQuiz:state.nextQuiz, instructions:state.instructions, instruction:state.instruction,passage:state.passage }

        case DELETE_QUIZ_QUESTION:
            return { loading: false, quiz_id:state.quiz_id, title: state.title, questions:state.questions.filter(cquestion => cquestion._id != action.payload),score:state.score, attempted:state.attempted, concept:state.concept, pageSEO:state.pageSEO, nextQuiz:state.nextQuiz, instructions:state.instructions, instruction:state.instruction,passage:state.passage }

        case CREATE_ADD_QUIZ_QUESTION:
            return { loading: false, quiz_id:state.quiz_id, title: state.title, questions:[action.payload, ...state.questions],score:state.score, attempted:state.attempted, concept:state.concept, pageSEO:state.pageSEO, nextQuiz:state.nextQuiz, instructions:state.instructions, instruction:state.instruction,passage:state.passage }

        case EDIT_QUIZ_CONCEPT:
            return { loading: false, quiz_id:state.quiz_id, title: state.title, questions:state.questions,score:state.score, attempted:state.attempted, concept:action.payload, pageSEO:state.pageSEO, nextQuiz:state.nextQuiz, instructions:state.instructions, instruction:state.instruction, passage:state.passage }


        case EDIT_QUIZ_INSTRUCTIONS:
            return { loading: false, quiz_id:state.quiz_id, title: state.title, questions:state.questions,score:state.score, attempted:state.attempted, concept:state.concept, pageSEO:state.pageSEO, nextQuiz:state.nextQuiz, instructions:state.instructions, instruction:action.payload.instruction, passage:state.passage }
    

        case EDIT_QUIZ_PASSAGE:
            return { loading: false, quiz_id:state.quiz_id, title: state.title, questions:state.questions,score:state.score, attempted:state.attempted, concept:state.concept, pageSEO:state.pageSEO, nextQuiz:state.nextQuiz, instructions:state.instructions, instruction:action.payload.instruction, passage:action.paylod}
    



        default:
            return state
    }


}