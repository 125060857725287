import axios from 'axios';
// import {setAlert} from './alertActions';
import {

    LOAD_QUIZLIST,
    LOAD_QUIZLIST_ERROR,
    LOAD_QUIZ_BY_ID,
    LOAD_QUIZ_ERROR,
    CHECK_SOLUTION,



} from '../constants/quizConstants'

//Load quiz by id
export const loadQuizList = () => async (dispatch, getState) => {

    try{

        const config = {
            headers: {
                'Content-Type': 'application/json',
            }
        }


        const full_url = `/api/quiz`

        const { data } = await axios.get(full_url,config)

        console.log('tyzlle : ',data)


        dispatch({
            type: LOAD_QUIZLIST,
            payload: data
        })

    
    }catch (error){

        dispatch({
            type: LOAD_QUIZLIST_ERROR,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })

    }

}




//Load quiz by id
export const loadQuizListBySection = (section_id) => async (dispatch, getState) => {

    try{

        const config = {
            headers: {
                'Content-Type': 'application/json',
            }
        }


        const full_url = `/api/quiz`

        const { data } = await axios.put(full_url,{section_id},config)

        console.log('tyzlle : ',data)


        dispatch({
            type: LOAD_QUIZLIST,
            payload: data
        })

    
    }catch (error){

        dispatch({
            type: LOAD_QUIZLIST_ERROR,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })

    }

}






export const loadQuizById = (quiz_id) => async (dispatch, getState) => {

    try{

        const config = {
            headers: {
                'Content-Type': 'application/json',
            }
        }


        const full_url = `/api/quiz/${quiz_id}`

        console.log("Full Url ", full_url)

        const { data } = await axios.get(full_url,config)

        console.log('Quiz in : ',data)


        dispatch({
            type: LOAD_QUIZ_BY_ID,
            payload: data
        })

    
    }catch (error){

        dispatch({
            type: LOAD_QUIZ_ERROR,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })

    }

}






export const loadQuizByURL = (quiz_url) => async (dispatch, getState) => {

    try{

        const config = {
            headers: {
                'Content-Type': 'application/json',
            }
        }


        const full_url = `/api/quiz/url/${quiz_url}`

        console.log("Full Url ", full_url)

        const { data } = await axios.get(full_url,config)

        console.log('Quiz in : ',data)


        dispatch({
            type: LOAD_QUIZ_BY_ID,
            payload: data
        })

    
    }catch (error){

        dispatch({
            type: LOAD_QUIZ_ERROR,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })

    }

}



export const checkSolution = (question_id, answer) => async (dispatch, getState) => {

    try{

        const config = {
            headers: {
                'Content-Type': 'application/json',
            }
        }


        const full_url = `/api/quiz/answer/${question_id}`

        const { data } = await axios.post(full_url,{answer},config)

        console.log('puzzle : ',data)


        dispatch({
            type: CHECK_SOLUTION,
            payload: data
        })

    
    }catch (error){

        dispatch({
            type: LOAD_QUIZ_ERROR,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })

    }

}


