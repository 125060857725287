import React from 'react'
import { Helmet } from 'react-helmet'

const Meta = ({title, description, keywords}) => {
    return (
        <Helmet>
            <title> {title}</title>
            <meta name='description' content={description}/>
            <meta name='keywords' content={keywords}/>
        </Helmet>
    )
}

Meta.defaultProps = {
    title: 'Welcome To Qwz4U, your favourite English Quiz App',
    description: 'Master English with Qwz4u, the leading English quiz app offering comprehensive grammar, vocabulary, and reading comprehension quizzes. Designed for learners at all levels, our app adapts to your pace, providing instant feedback, detailed explanations, and progress tracking to enhance your language skills effectively. Join our community for daily challenges and improve your English fluency today!',
    keywords: 'Qwz4u, English quiz app, learn English, grammar quizzes, vocabulary builder, reading comprehension, language learning, improve English skills, educational app, English practice, language mastery'
}

export default Meta
