import React, {useState, useEffect, Fragment} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Button, Card, Row, Col, Badge, ListGroup } from 'react-bootstrap'

import {answerQs, answerQTest} from '../../../actions/qsActions'


const QTestItemTake = ({ question, index, mode_in, card_id }) => {

    const dispatch = useDispatch()

    const [keyword, setKeyword] = useState('')
    const [solution, setSolution] = useState('')
    const [showSolution, setShowSolution] = useState(false)

    const updateSolution = (val_in) => {
        setSolution(val_in)

    }


    const submitAnswer = (e) => {
        e.preventDefault()

        dispatch(answerQTest(question._id, solution, question.hashtag, mode_in, card_id))

    }




    useEffect(() => {


    }, [])




    return (
        <>

            <Card style={{padding:'5px', margin:'10px', borderRadius:'20px', border:'None', backgroundColor:'inherit'}}>
                {/* <Card.Header>



                </Card.Header> */}

                <Card.Body style={{padding:'2px'}}>

                <span>
                    {/* <Button style={{ height: '20px', width: '20px',
                    backgroundColor: question.subject == 'English' ? 'yellow' : 'green',
                    marginRight:'5px'}}> </Button> */}
                <Button style={{ height: '20px', width: '20px',
                        backgroundColor:'#00416a',
                    marginRight:'5px', marginBottom:'5px'}}></Button>
                
                </span>
                <span style={{fontWeight:'600'}}><strong>{question.Qtext}</strong></span>

               

                    
                {question.Qtype=='MCQ' &&  question.options && (
                            <Fragment>

                                <Form.Group className="mb-3">

                         <Row>
                            <Col>

                                    {question.options[1] && (
                                        <Form.Check
                                        type="radio"
                                        label={'a)' + question.options[1]  }
                                        name={"formHorizontalRadios" + "_"  + question._id.toString() + "_"+ index}
                                        id={"formHorizontalRadios1"  + "_" + question._id.toString() + "_"+ index}
                                        onChange={()=>updateSolution(1)}
                                        checked={solution==1 ? true : false}
                                        //readOnly={true}
                                        />
                                    )}



                            </Col>
                        {/* </Row>
                        <Row> */}
                            <Col>
                                
                                {question.options[2] && (

                                    

                                <Form.Check
                                    type="radio"
                                    label={'b)' + question.options[2]}
                                    name={"formHorizontalRadios" + "_" + question._id.toString() + "_"+ index}
                                    id={"formHorizontalRadios2"  + "_" + question._id.toString() + "_"+ index}
                                    onChange={()=>updateSolution(2)}
                                    checked={solution==2 ? true : false}
                                    //readOnly={true}
                                    />
                                )}


                            </Col>
                        </Row>
                        <Row>
                            <Col>
                               {question.options[3] && (


                                <Form.Check
                                    type="radio"
                                    label={'c)' + question.options[3]}
                                    name={"formHorizontalRadios" + "_" + question._id.toString() + "_"+ index}
                                    id={"formHorizontalRadios3"  + "_" + question._id.toString() + "_"+ index}
                                    onChange={()=>updateSolution(3)}
                                    checked={solution==3 ? true : false}
                                    //readOnly={true}
                                    />

                               )}

                            </Col>

                            <Col>   
                                {question.options[4] && (

                                <Form.Check
                                    type="radio"
                                    label={'d)' + question.options[4]}
                                    name={"formHorizontalRadios" + "_" + question._id.toString() + "_"+ index}
                                    id={"formHorizontalRadios4"  + "_" + question._id.toString() + "_"+ index}
                                    onChange={()=>updateSolution(4)}
                                    checked={solution==4 ? true : false}
                                    //readOnly={true}
                                    />

                                )}

                            </Col>
                        </Row>


                        </Form.Group>

                        <Badge style={{backgroundColor:'#8b008b', color:'white', padding:'10px',borderRadius:'5px', cursor:'pointer'}} onClick={submitAnswer}>Submit</Badge>


                            </Fragment>
                        )}


                        {question.isAnswered && question.correct && (
                            <>
                            {question.correct == 'Correct' && (
                            <p style={{padding:'5px', color:'greem'}}>{question.correct}. </p>
                            )}


                            {question.correct == 'Wrong' && (
                                <p style={{padding:'5px', color:'red'}}>{question.correct}. Please Try again</p>
                             )}

                            </>
                        )}

                        {question.isAnswered && question.correct && question.learn && question.learn.length >0 && (
                            <>
                            {/* <p className='h5' style={{color:'blue'}}>Learn</p> */}
                            <ul style={{ backgroundColor:'inherit'}}>
                                {question.learn.map((learn, index)=>(
                                    <li style={{ backgroundColor:'inherit', border:'None', fontStyle:'italic'}}>
                                        {learn.text}
                                    </li>
                                ))}
                            </ul>
                            </>
                        )}


                        {/* {showSolution && (
                            <>
                                <p>Ans:</p>

                                <p>Learn:</p>
                            </>
                        )} */}



                </Card.Body>
            </Card>

        </>
    )
}

export default QTestItemTake
