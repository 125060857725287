import React , {useState, useEffect, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Container, Card, Form, Button, ListGroup, OverlayTrigger, Popover, Tooltip, Carousel, InputGroup, FormControl, Table, Radio, Badge } from 'react-bootstrap'



import {updateInstructions} from '../../actions/creatorActions'

export const CreatorInstructionsCard = ({  }) => {


  const dispatch = useDispatch()

  const CreateQuizDetail = useSelector(state => state.CreateQuizDetail)
  const { quiz_id, instruction } = CreateQuizDetail



  const [viewMode, setViewMode] = useState("view")
  const [qtext, setQText] = useState("")

  const toggleViewMode = () => {
    if(viewMode == 'view'){
        setViewMode('edit')
    }else{
        setViewMode('view')
    }
  }


  useEffect(() => {

      setQText(instruction)

  }, []);


  const updateInstructionsRequest = () => {
    dispatch(updateInstructions(quiz_id, qtext))
    setViewMode('view')
  }


  
  return (

    <>
    
    <Card style={{padding:'20px', borderRadius:'20px', borderColor:'#a516f2', marginBottom:'20px'}}>

        <p className='h5'>Instructions</p>

        <Button onClick={()=>toggleViewMode()}>Edit</Button>


        {viewMode == 'view' ? (
            <>

                   
                {/* {qtext && qtext.split("\n").map((cinstr, index)=>(
                    <p>{cinstr}</p>
                ))} */}


                {qtext && qtext.split("\n").map((cinstr, index)=>(
                    <p className='h5'>{cinstr}</p>
                ))}

            
            
            </>
        ): (
            <>
                <Form.Control type='text' placeholder='Question' value={qtext} onChange={(e) => setQText(e.target.value)}> 
                </Form.Control>

                <Button onClick={()=>updateInstructionsRequest()}>Update</Button>
            </>
            
        )}



        


    </Card>


    
    </>

  );
};

export default CreatorInstructionsCard;
