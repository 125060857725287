import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import {QuizListReducer, QuizDetailReducer} from './reducers/quizReducer'
import {CreateQuizListReducer, CreateQuizDetailReducer} from './reducers/creatorReducer'
import {UserReducer} from './reducers/userReducer'

import {slcardListReducer, sltopicListReducer, learningPathReducer, slconceptnavReducer} from './reducers/smartlearnReducer'

const reducer = combineReducers({

    QuizList: QuizListReducer, 
    QuizDetail: QuizDetailReducer,

    CreateQuizList: CreateQuizListReducer, 
    CreateQuizDetail: CreateQuizDetailReducer,

    userR: UserReducer,

    slcardList: slcardListReducer, 
    sltopicList: sltopicListReducer,
    learningPath: learningPathReducer,
    slconceptnav: slconceptnavReducer,

})



//const languageItemsFromStorage = localStorage.getItem('language') ? JSON.parse(localStorage.getItem('language')) : 'English'

const userInfoItemsFromStorage = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null

const initialState = {

    userR: { user: userInfoItemsFromStorage },

} 

const middleware = [thunk]
const store = createStore(
    reducer, 
    initialState, 
    composeWithDevTools(applyMiddleware(...middleware))
)

export default store