import React,{useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Pagination } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { Form, Button, Row, Col, Card, Container, Badge } from 'react-bootstrap'


const ImageCanvas = ({ operationlist }) => {


    const dispatch = useDispatch()

    

    useEffect(() => {


    }, [])


    return  (
        <>

            {operationlist && (



            <svg width={operationlist.svgparams.width} height={operationlist.svgparams.height} style={{border:'None'}}>

                {operationlist && operationlist.opers && operationlist.opers.map((oper, index)=>(

                    <>

                        {oper.type == 'rectangle' && (
                            <rect x={oper.startx} y={oper.starty} width={oper.width} height={oper.height} fill='none' stroke='black'/>
                        )}


                        {oper.type == 'circle' && (
                            <circle cx={oper.startx} cy={oper.starty} r={oper.radius} fill='none' stroke='black'/>
                        )}


                        {oper.type == 'text' && (
                            <text x={oper.startx} y={oper.starty} fill="red" font-size={oper.fontsize} transform={oper.transform}> {oper.text}</text>
                        )}

                        {oper.type == 'circle' && (
                            <circle cx={oper.startx} cy={oper.starty} r={1} stroke='black'/>
                        )}  


                        {oper.type == 'line' && (
                            <line x1={oper.startx1} y1={oper.starty1} x2={oper.startx2} y2={oper.starty2} stroke='black'/>
                        )}

                        {oper.type == 'triangle' && (
                            <>
                            <line x1={oper.startx1} y1={oper.starty1} x2={oper.startx2} y2={oper.starty2} stroke='black' />
                            <line x1={oper.startx2} y1={oper.starty2} x2={oper.startx3} y2={oper.starty3} stroke='black' />
                            <line x1={oper.startx3} y1={oper.starty3} x2={oper.startx1} y2={oper.starty1} stroke='black' />
                            </>
                        )}

                        {oper.type == 'polygon' && (
                            <polygon points={oper.points} stroke='black' fill='None'/>
                        )}


                        {oper.type == 'polyline' && (
                            <polyline points={oper.points} stroke='black' fill='None'/>
                        )}
                    
                        {oper.type == 'curve' && (
                            <path d={oper.points} stroke='blue' fill='None'/>
                        )}
                    
                    
                    
                    </>

                ))}



            </svg>

            )}


        </>
    )
}

export default ImageCanvas
