import React , {useState, useEffect,  Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Row, Col, Card, Button, Form,  OverlayTrigger, Tooltip, InputGroup, ListGroup, Badge } from 'react-bootstrap'

import Moment from 'react-moment';
import DateFormatted from '../DateFormatted'


import YouTube from 'react-youtube';

import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile,
    deviceDetect
  } from "react-device-detect";

export const SLVideo = ({video}) => {

    const dispatch = useDispatch()


    var opts = {
        height: '400px',
        width: '650px',
        playerVars: {
          // https://developers.google.com/youtube/player_parameters
          autoplay: 0,
        },
    };

    if(isMobile){

        opts = {
            width: '250px',
            height: '150px',
            playerVars: {
              // https://developers.google.com/youtube/player_parameters
              autoplay: 0,
            },
          };

    }




    const onReady = (event) => {
        event.target.pauseVideo();
        console.log('On Ready')
    }

    const onStateChange = (event) => {
        console.log('On State Change')
    }

    const onPlay = (event) => {
        console.log('On Play : ',video.url)
        //trackView()
    }



    useEffect(() => {
        


        
    }, [])




    return (

        <Card style={{margin:'10px', padding:'5px', border:'None'}}>
         
            <YouTube videoId={video.url} opts={opts} onReady={onReady} onStateChange={onStateChange} onPlay={onPlay}  />

        </Card>
    )
}


export default SLVideo
