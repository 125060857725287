import axios from 'axios';
import { setAlert } from './alertActions';
import {

    SLCARD_LIST,
    SLCARD_LIST_FAIL,
    SLCARD_LIST_GENERATE,
    SLCARD_EXAMPLE_GENERATE,
    SLCARD_EXAMPLE_FAIL,

    SLTOPIC_LIST,
    SLTOPIC_LIST_FAIL,

    SLCARD_TEST_GENERATE,
    SLCARD_TEST_FAIL,

    SEARCH_DOUBT ,
    SEARCH_DOUBT_FAIL,

    SLCARD_NAV,
    SLCARD_NAV_FAIL


} from '../constants/smartlearnConstants'




export const generateSmartLearnCard = (hashtag, type) => async (dispatch, getState) => {

    try{

        //const { userLogin: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                //Authorization: `Bearer ${userInfo.token}`
            }
        }


        var language = 'English'

        //localStorage.setItem('smartlearnsession', JSON.stringify(data))


        const { data } = await axios.post(`/api/smartlearn`, {hashtag, type, language}, config)

        console.log(data)


        dispatch({
            type: SLCARD_LIST_GENERATE,
            payload: data.card
        })


        dispatch({
            type: SLCARD_NAV,
            payload: data.nav
        })

    }catch (error){

        dispatch({
            type: SLCARD_LIST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })

    }

}



export const generateSmartLearnCardUrl = (concept_url) => async (dispatch, getState) => {

    try{

        //const { userLogin: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                //Authorization: `Bearer ${userInfo.token}`
            }
        }


        var language = 'English'

        //localStorage.setItem('smartlearnsession', JSON.stringify(data))

        console.log("Gen URL actions ", concept_url)

        const { data } = await axios.get(`/api/smartlearn/url/${concept_url}`, config)

        console.log(data)


        dispatch({
            type: SLCARD_LIST_GENERATE,
            payload: data.card
        })


        dispatch({
            type: SLCARD_NAV,
            payload: data.nav
        })

    }catch (error){

        dispatch({
            type: SLCARD_LIST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })

    }

}



export const generateExampleSLCard = (hashtag) => async (dispatch, getState) => {

    try{

        //const { userLogin: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                //Authorization: `Bearer ${userInfo.token}`
            }
        }

        
        var type = 'example'
        var language = 'English'

        //localStorage.setItem('smartlearnsession', JSON.stringify(data))


        const { data } = await axios.post(`/api/smartlearn`, {hashtag, type, language}, config)

        console.log(data)


        // dispatch({
        //     type: SLCARD_EXAMPLE_GENERATE,
        //     payload: data
        // })


        dispatch({
            type: SLCARD_LIST_GENERATE,
            payload: data.card
        })


        dispatch({
            type: SLCARD_NAV,
            payload: data.nav
        })

    }catch (error){

        dispatch({
            type: SLCARD_EXAMPLE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })

    }

}






export const generateVideoSLCard = (hashtag) => async (dispatch, getState) => {

    try{

        //const { userLogin: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                //Authorization: `Bearer ${userInfo.token}`
            }
        }

        
        var type = 'video'
        var language = 'English'

        //localStorage.setItem('smartlearnsession', JSON.stringify(data))


        const { data } = await axios.post(`/api/smartlearn`, {hashtag, type, language}, config)

        console.log(data)


        // dispatch({
        //     type: SLCARD_EXAMPLE_GENERATE,
        //     payload: data
        // })


        dispatch({
            type: SLCARD_LIST_GENERATE,
            payload: data.card
        })


        dispatch({
            type: SLCARD_NAV,
            payload: data.nav
        })

    }catch (error){

        dispatch({
            type: SLCARD_EXAMPLE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })

    }

}






export const generateTestSLCard = (hashtag) => async (dispatch, getState) => {

    try{

        //const { userLogin: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                //Authorization: `Bearer ${userInfo.token}`
            }
        }

        
        var type     = 'example'
        var language = 'English'

        //localStorage.setItem('smartlearnsession', JSON.stringify(data))


        const { data } = await axios.post(`/api/smartlearn`, {hashtag, type, language}, config)

        console.log(data)


        dispatch({
            type: SLCARD_TEST_GENERATE,
            payload: data
        })

    }catch (error){

        dispatch({
            type: SLCARD_TEST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })

    }

}


export const listRecentTopics = () => async (dispatch, getState) => {

    try{

        //const { userLogin: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                //Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.get(`/api/smartlearn/topics`, config)

        console.log(data)


        dispatch({
            type: SLTOPIC_LIST,
            payload: data
        })

    }catch (error){

        dispatch({
            type: SLTOPIC_LIST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })

    }

}


export const searchRecentTopics = (mode, searchString) => async (dispatch, getState) => {

    try{

        //const { userLogin: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                //Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.get(`/api/smartlearn/topics/search?keyword=${searchString}&mode=${mode}`, config)

        console.log(data)


        dispatch({
            type: SLTOPIC_LIST,
            payload: data
        })

    }catch (error){

        dispatch({
            type: SLTOPIC_LIST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })

    }

}




export const submitDoubt = (searchString) => async (dispatch, getState) => {

    try{

        //const { userLogin: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                //Authorization: `Bearer ${userInfo.token}`
            }
        }

        var language = 'English'
        var type = 'concept'

        const { data } = await axios.post(`/api/smartlearn/doubt`, {searchString, language, type}, config)

        console.log(data)



        dispatch({
            type: SLCARD_LIST_GENERATE,
            payload: data.card
        })


        dispatch({
            type: SLCARD_NAV,
            payload: data.nav
        })

    }catch (error){

        dispatch({
            type: SLCARD_LIST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })

    }

}


