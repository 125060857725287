import React , {useState, useEffect, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Container, Card, Form, Button, ListGroup, OverlayTrigger, Popover, Tooltip, Carousel, InputGroup, FormControl, Table, Radio, Badge } from 'react-bootstrap'


import {updatePassage} from '../../actions/creatorActions'


export const PassageParaEdit = ({ quiz_id, index, para }) => {


  const dispatch = useDispatch()


  const [qtext, setQText] = useState("")


  const [viewMode, setViewMode] = useState("view")
  const tooggleView = () => {
    if(viewMode == 'view'){
        setViewMode('edit')
    }else{
        setViewMode('view')
    }
  }



  useEffect(() => {

    setQText(para)

  }, []);


  const updatePassageRequest = () => {
    dispatch(updatePassage(quiz_id, 'para', '',index, qtext))
    setViewMode('view')
  }


  
  return (

    <>

        
        
        
        <>
            {viewMode == 'view' ? (
                <p className='h5'>{para} <Badge style={{border:'solid'}} onClick={()=>tooggleView()}>Edit</Badge></p>
            ): (
                <>
                
                <Form.Control as='textarea' rows={8} placeholder='Question' value={qtext} onChange={(e) => setQText(e.target.value)}> 
                </Form.Control>

                <Badge onClick={()=>updatePassageRequest()} style={{border:'solid', maxWidth:'20vw'}}>Update</Badge>
                
                </>
            )}
           
        </>
        
    

    
    </>

  );
};

export default PassageParaEdit;
