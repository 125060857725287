import React, {useState,  useEffect, Fragment} from 'react'
import { Form, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'

import YouTube from 'react-youtube';

import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile,
    deviceDetect
  } from "react-device-detect";

const VideoComponent = ({ videoId, mode }) => {


    const dispatch = useDispatch()

    const [viewed, setViewed] = useState(false)
 


    const trackView = () => {

        
        
    }



    if(isMobile){


        var opts = {
            width: '80vh',
            height: '30wh',
            playerVars: {
              // https://developers.google.com/youtube/player_parameters
              autoplay: 0,
            },
          };
    
        if(mode == 'thumbnail'){
            opts = {
                // height: '135',
                height: '75px',
                width: '120px',
                playerVars: {
                  // https://developers.google.com/youtube/player_parameters
                  autoplay: 0,
                },
              };
        }

        if(mode == 'conversation'){
          opts = {
              // height: '135',
              height: '75px',
              width: '120px',
              playerVars: {
                // https://developers.google.com/youtube/player_parameters
                autoplay: 0,
              },
            };
      }
    
        if(mode == 'card'){
            opts = {
                width: '250px',
                height: '150px',
                playerVars: {
                  // https://developers.google.com/youtube/player_parameters
                  autoplay: 0,
                },
              };
        }
    
        if(mode == 'home'){
                opts = {
                width: '250px',
                height: '150px',
                playerVars: {
                  // https://developers.google.com/youtube/player_parameters
                  autoplay: 0,
                },
              };
        }

    }else{


        var opts = {
            height: '30%',
            width: '40%',
            playerVars: {
              // https://developers.google.com/youtube/player_parameters
              autoplay: 0,
            },
          };
    
        if(mode == 'thumbnail'){
            opts = {
                // height: '135',
                width: '200px', // '150px',
                height: '120px', //'90px',
                playerVars: {
                  // https://developers.google.com/youtube/player_parameters
                  autoplay: 0,
                },
              };
        }

        if(mode == 'conversation'){
          opts = {
              // height: '135',
              width: '300px', // '150px',
              height: '180px', //'90px',
              playerVars: {
                // https://developers.google.com/youtube/player_parameters
                autoplay: 0,
              },
            };
      }
    
        if(mode == 'card'){
            opts = {
                height: '400px',
                width: '650px',
                playerVars: {
                  // https://developers.google.com/youtube/player_parameters
                  autoplay: 0,
                },
              };
        }
    
        if(mode == 'home'){
              //   opts = {
              //   height: '300px',
              //   width: '500px',
              //   playerVars: {
              //     // https://developers.google.com/youtube/player_parameters
              //     autoplay: 1,
              //   },
              // };


              opts = {
                height: '200px',
                width: '320px',
                playerVars: {
                  // https://developers.google.com/youtube/player_parameters
                  autoplay: 0,
                },
              };
        }

    }



 
    const submitHandler = (e) => {
        e.preventDefault()

    }

    const onReady = (event) => {
        event.target.pauseVideo();
        console.log('On Ready')
    }

    const onStateChange = (event) => {
        console.log('On State Change')
    }

    const onPlay = (event) => {
        console.log('On Play : ',viewed)
        trackView()
    }


    useEffect(() => {
        //getQandA()
    }, [mode])

    const trackClicks = () => {
        console.log('Click')
    }



    return (


        <div onClick={trackClicks}>
        
        <YouTube videoId={videoId} opts={opts} onReady={onReady} onStateChange={onStateChange} onPlay={onPlay}  />

        </div>

    )
}

export default VideoComponent
