import produce from 'immer';
import {

    SLCARD_LIST,
    SLCARD_LIST_FAIL,
    SLCARD_LIST_GENERATE,
    SLCARD_EXAMPLE_GENERATE,
    SLCARD_EXAMPLE_FAIL,

    SLTOPIC_LIST,
    SLTOPIC_LIST_FAIL,

    UPDATE_LEARNING_PATH,
    LEARNING_PATH_FAIL,
    CLEAR_LEARNING_PATH,

    SLCARD_TEST_GENERATE,
    SLCARD_TEST_FAIL,
    SEARCH_DOUBT,
    SEARCH_DOUBT_FAIL,

    SLCARD_NAV,
    SLCARD_NAV_FAIL



} from '../constants/smartlearnConstants'


import {

    Qs_SUBMIT_ANSWER_SL

} from '../constants/qsConstants'




export const slcardListReducer = (state = { slcards: []}, action) => {

    switch(action.type){
        case SLCARD_LIST:
            return { loading: true, slcards:action.payload}
        
        
        case SLCARD_LIST_GENERATE:
            return { loading: false, slcards:[action.payload, ...state.slcards] }
        
        
        case SLCARD_LIST_FAIL:
            return { loading: false, error: action.payload, slcards:state.slcards }
        
        
        case SLCARD_EXAMPLE_GENERATE:
            return { loading: false,                 
                slcards: state.slcards.map(slcard => slcard.hashtag === action.payload.hashtag ? { ...slcard, example:action.payload}:slcard), 
                question:state.question,
                requestType:state.requestType
            }
        case Qs_SUBMIT_ANSWER_SL:
            return { loading: false,                 
                slcards: state.slcards.map(slcard => slcard.id === action.payload.card_id ? { ...slcard, example:{
                    ...slcard.example, answer:action.payload.submitted, correct:action.payload.correct, isAnswered:true, learn:action.payload.learn}
                }:slcard), 
                
                question:state.question,
                requestType:state.requestType
            }

        case SEARCH_DOUBT:
            return { loading: false, slcards:[action.payload.answer, ...state.slcards], question:action.payload.questions, requestType:action.payload.requestType, practice:action.payload.practice }

        // case Qs_SUBMIT_ANSWER_SL:
        //     return produce(state, draft => {
        //         slcards =  state.slcards.map(slcard => slcard.hashtag === action.payload.hashtag ? {});
        //         loading =  false;
        //         question = state.question;
        //         requestType = state.requestType;

        //     });

        case SLCARD_EXAMPLE_FAIL:
            return { loading: false, error: action.payload, slcards:state.slcards, question:state.question, requestType:state.requestType }
        default:
            return state
    }

}


export const sltopicListReducer = (state = { sltopics: []}, action) => {

    switch(action.type){
        case SLTOPIC_LIST:
            return { loading: true, sltopics: action.payload }
        case SLTOPIC_LIST_FAIL:
            return { loading: false, error: action.payload, sltopics:state.sltopics }
        default:
            return state
    }

}

export const learningPathReducer = (state = { learningpath: []}, action) => {

    switch(action.type){
        case UPDATE_LEARNING_PATH:
            return { loading: true, learningpath: action.payload }
        case LEARNING_PATH_FAIL:
            return { loading: false, error: action.payload, learningpath:state.learningpath }
        case CLEAR_LEARNING_PATH:
            return { loading: false, error: action.payload, learningpath:[] }
        default:
            return state
    }

}


export const slconceptnavReducer = (state = { conceptnav: []}, action) => {

    switch(action.type){
        case SLCARD_NAV :
            return { loading: true, conceptnav: action.payload }
        case SLCARD_NAV_FAIL:
            return { loading: false, error: action.payload, conceptnav:state.conceptnav }
        default:
            return state
    }

}


