import React , {useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Container, Card, Form, Button, ListGroup, OverlayTrigger, Popover, Tooltip, InputGroup, FormControl, Table } from 'react-bootstrap'


import Carousel from 'react-bootstrap/Carousel';
import VideoComponent from '../components/VideoComponent'

import LandingDesktop from '../components/landing/LandingDesktop'
import LandingMobile from '../components/landing/LandingMobile'

import Meta from '../components/Meta'


import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile,
    deviceDetect
  } from "react-device-detect";

export const LandingScreen = ({match, history}) => {

    
    const dispatch = useDispatch()



    useEffect(() => {


    }, [])




    return(

        <>
        
        <Meta title={"Qwz4u: Engish Quizzes for Exams and Fun"} description={"Qwz4u provides quizzes, tests and materials to learn for exams"} />


        {isMobile ? (
            
            <LandingMobile />
        ): (
            <LandingDesktop />
        )}

{/* 

        <div style={{marginTop:'0px'}}>

        <section style={{backgroundColor:'#f0f2f2'}}>

            <div style={{textAlign:'center', marginTop:'0px', padding:'10px'}} >

                <p className='h1'>Learning English : Made Easy and Fun</p>
                <p className='h3'>A great resource to prepare Engish for Govt Competitive Exams</p>
           
                <Link to='/quiz'>
                <Button style={{width:'25vw', borderRadius:'20px', backgroundColor:'#bb5ced'}}>Click Here to start Quizzes</Button>
                </Link>
                
           
           
            </div>

        </section>



        <section class="ai-model" >

            <Row>
                <Col style={{paddingTop:'15vh'}}>
                <p className='h2'>Get started with Simple English Quizzes on basic topics</p>
                <p className='h5'>Make learning fun for your students.</p>
        
                </Col>
                <Col>
                {isMobile ? (
                    <img src="SimpleQuizzes.jpg" alt="Word Usage" style={{width:'90vw'}}/>
                    ) : (
                        <img src="SimpleQuizzes.jpg" alt="Word Usage" style={{height:'50vh'}}/>
                    )}
                </Col>
            </Row>
        

       
        
        </section>

        <section class="ai-model" style={{backgroundColor:'#f0f2f2'}} >

        <Row>
                <Col >
                {isMobile ? (
                <img src="Grammar.jpg" alt="Grammar Quizzes" style={{width:'90vw'}}/>
            ) : (
                <img src="Grammar.jpg" alt="Grammar Quizzes" style={{height:'50vh'}}/>
            )}
                </Col>
                <Col style={{paddingTop:'15vh'}}>
                <p className='h2'>Practice the basics of Grammar</p>
                <p className='h5'>Learn about nouns, pronouns ,adjectives, verbs and more.</p>
            
                </Col>
            </Row>
           
            
           
        
        </section>

        <section class="ai-model" >

        <Row>
                <Col style={{paddingTop:'15vh'}}>
                <p className='h2'>Practice Comprehension Passages till you master how to solve them</p>
                <p className='h5'>A number of exam like passages are shown.</p>
            
                </Col>
                <Col >
                {isMobile ? (
                <img src="Comprehension.jpg" alt="Comprehension Example"  style={{width:'90vw'}}/>
            ) : (
                <img src="Comprehension.jpg" alt="Comprehension Example"  style={{height:'50vh'}}/>
            )}
                </Col>
            </Row>
           
            
            
        
        </section>





        <section class="ai-model" style={{backgroundColor:'#f0f2f2'}}>
        <Row>
                <Col>

                {isMobile ? (
                <img src="LearnSess.jpg" alt="Learning Sessions"  style={{width:'90vw'}}/>
                ) : (
                    <img src="LearnSess.jpg" alt="Learning Sessions"  style={{height:'50vh'}}/>
                )}

            
                </Col>
                <Col style={{paddingTop:'15vh'}}>
                <p className='h2'>Use Our Learning Sessions to get a more guided learning experience.</p>
        
                <p className='h5'>A quick read on the basic concepts and new developments in a fun manner.</p>
        
                </Col>
            </Row>
        

        </section>


     

        <section class="ai-model" style={{backgroundColor:'white'}}>
        <Row>
                <Col>
                <Card style={{margin:'10px', padding:'5px', border:'None'}}>
                                <Card.Header style={{border:'None', margin:'3px', borderRadius:'10px'}}>

                        <ListGroup horizontal style={{overflow:'scroll'}}>
                            <ListGroup.Item>
                                <VideoComponent videoId='mY__YYyu7Ko'  mode='thumbnail'/>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <VideoComponent videoId='OBt0q8kNbD4'  mode='thumbnail'/>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <VideoComponent videoId='aGihOWAmI9M'  mode='thumbnail'/>
                            </ListGroup.Item>
                           

                           

                            
                        </ListGroup>
                    </Card.Header>
                    </Card>
                </Col>
                <Col style={{paddingTop:'7vh'}}>
                <p className='h2'>Qwz4U on Youtube</p>
        
            

                 <p className='h5'>Checkout our youtube channel for fun learning videos.</p>
        
                </Col>
            </Row>
       

        </section>



      


        <section class="ai-model" style={{backgroundColor:'#f0f2f2'}}>
       

        
                <p className='h2' style={{textAlign:'center'}}>FAQs</p>
        
            
             
                <ListGroup>
                <ListGroup.Item style={{border:'None'}}>
                        <Card style={{border:'None'}}>
                            <Card.Header style={{borderRadius:'20px', borderColor:'#b861fb', margin:'5px'}}>
                                <p className='h3'>Why is Qwz4U so test centric ?</p>
                            </Card.Header>
                                <p className='h5' style={{margin:'20px'}}> We believe that tests are a great way to learn and to evaluate understanding of a topic. Frequent testing reduces the fear of tests which will make it easier for students to clear their exams.</p>
                        </Card>
                    </ListGroup.Item>

                    <ListGroup.Item style={{border:'None'}}>
                        <Card style={{border:'None'}}>
                            <Card.Header style={{borderRadius:'20px', borderColor:'#b861fb', margin:'5px'}}>
                                <p className='h3'>What subjects does Qwz4U have tests on ?</p>
                            </Card.Header>
                                <p className='h5' style={{margin:'20px'}}> Qwz4U is currently focussed on English topics. This is because we are aiming to primarily help students preparing for competitive exams like the Banking Exam, SSC.</p>
                        </Card>
                    </ListGroup.Item>

                    <ListGroup.Item style={{border:'None'}}>
                        <Card style={{border:'None'}}>
                            <Card.Header style={{borderRadius:'20px', borderColor:'#b861fb', margin:'5px'}}>
                                <p className='h3'>How much do tests and courses on Qwz4U cost?</p>
                            </Card.Header>
                                <p className='h5' style={{margin:'20px'}}> Currently all our courses and tests are free. There is also no sign up or login. </p>
                        </Card>
                    </ListGroup.Item>


                    <ListGroup.Item style={{border:'None'}}>
                        <Card style={{border:'None'}}>
                            <Card.Header style={{borderRadius:'20px', borderColor:'#b861fb', margin:'5px'}}>
                                <p className='h3'>Does Qwz4U have paid content?</p>
                            </Card.Header>
                                <p className='h5' style={{margin:'20px'}}> Not at the moment. We might bring in paid courses in the future.</p>
                        </Card>
                    </ListGroup.Item>

                    <ListGroup.Item style={{border:'None'}}>
                        <Card style={{border:'None'}}>
                            <Card.Header style={{borderRadius:'20px', borderColor:'#b861fb', margin:'5px'}}>
                                <p className='h3'>Is there a Qwz4U app?</p>
                            </Card.Header>
                                <p className='h5' style={{margin:'20px'}}> A Qwz4U app is currently under development and will be released soon.</p>
                        </Card>
                    </ListGroup.Item>


                </ListGroup>



          
           

        </section> */}






  {/* <section class="ai-model" style={{backgroundColor:'#f0f2f2'}}>
        <Row>
                <Col>
                <p className='h2'>Coming Soon</p>
        
            

        <p className='h5'>The Qwz4U app that brings learning to your devices.</p>
        

                </Col>
                <Col>
                {isMobile ? (
                <img src="ImageRecognition.png" alt="A representation of AI" style={{width:'90vw'}}/>
            ):(
                <img src="ImageRecognition.png" alt="A representation of AI" style={{height:'50vh'}}/>
            )}
                </Col>
            </Row>
        

        </section> */}



        {/* <section class="ai-model" style={{backgroundColor:'#f0f2f2'}}>
        <Row>
                <Col>
                <p className='h2'>About Us</p>
        
            

        <p className='h5'>Qwz4u</p>
        
                </Col>
                <Col>
                
            {isMobile ? (
                <img src="ImageRecognition.png" alt="A representation of AI" style={{width:'90vw'}}/>
            ):(
                <img src="ImageRecognition.png" alt="A representation of AI" style={{height:'50vh'}}/>
            )}
                </Col>
            </Row>
       

        </section> */}





{/* 
</div>
 */}






        </>

 



    )


}

export default LandingScreen


