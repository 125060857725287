import React , {useState, useEffect,  Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Row, Col, Card, Button, Form,  OverlayTrigger, Tooltip, InputGroup, ListGroup, Badge } from 'react-bootstrap'

import {submitDoubt} from '../../actions/smartlearnActions.js'


export const DoubtInput = ({index, slcard}) => {

    const dispatch = useDispatch()

    const [searchstring, setSearchstring] = useState('')

    const submitSearchRequest = (e) => {
        e.preventDefault()

        dispatch(submitDoubt(searchstring))

    }


    useEffect(() => {
        


        
    }, [])




    return (

   <>

   
   <Form onSubmit={submitSearchRequest} style={{marginBottom:'5px'}}>
        
        <InputGroup>
        <Form.Control type='text' placeholder='Enter a Topic' value={searchstring} onChange={(e) => setSearchstring(e.target.value)} style={{width:'5%', border:'solid' , borderColor:'#c2179a', borderRadius:'5px'}}> 
        </Form.Control>

        <Button type='submit' variant='outline-success' className='p-2'>
            <i class="fa fa-paper-plane" aria-hidden="true"></i>
        </Button>
        </InputGroup>
    </Form>
   
   
   </>
            


    )
}


export default DoubtInput
