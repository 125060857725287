import React , {useState, useEffect,  Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Row, Col, Card, Button, Form,  OverlayTrigger, Tooltip, InputGroup, ListGroup, Badge } from 'react-bootstrap'

import {searchRecentTopics} from '../../actions/smartlearnActions.js'

export const SearchConcepts = ({index, slcard}) => {

    const dispatch = useDispatch()

    const [searchstring, setSearchstring] = useState('')

    const submitSearchRequest = (e) => {
        e.preventDefault()

        dispatch(searchRecentTopics('string',searchstring))

    }

    const searchBySubject = (subject_in) => {

        dispatch(searchRecentTopics('subject', subject_in))
    }


    useEffect(() => {
        


        
    }, [])




    return (

   <>

    {/* <InputGroup>
   <Badge style={{padding:'5px', margin:'3px', backgroundColor:'#89de6f', color:'white', borderRadius:'5px'}} onClick={()=>searchBySubject('English')}>English</Badge>
   <Badge style={{padding:'5px', margin:'3px', backgroundColor:'#89de6f', color:'white', borderRadius:'5px'}} onClick={()=>searchBySubject('Maths')}>Maths</Badge>
   <Badge style={{padding:'5px', margin:'3px', backgroundColor:'#89de6f', color:'white', borderRadius:'5px'}} onClick={()=>searchBySubject('All')}>All</Badge>
   </InputGroup> */}
   
   <Form onSubmit={submitSearchRequest} style={{marginBottom:'5px'}}>
        
        <InputGroup>
        <Form.Control type='text' placeholder='Search by Topic or Concept' value={searchstring} onChange={(e) => setSearchstring(e.target.value)} style={{width:'5%'}}> 
        </Form.Control>

        <Button type='submit' variant='outline-success' className='p-2'>
            <i class="fa fa-search" aria-hidden="true"></i>
        </Button>
        </InputGroup>
    </Form>
   
   
   </>
            


    )
}


export default SearchConcepts
