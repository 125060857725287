import React , {useState, useEffect, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Container, Card, Form, Button, ListGroup, OverlayTrigger, Popover, Tooltip, Carousel, InputGroup, FormControl, Table, Radio, Badge } from 'react-bootstrap'

import PassageParaEdit from './PassageParaEdit'


import {updatePassage} from '../../actions/creatorActions'

export const CreatorPassageCard = ({  }) => {


  const dispatch = useDispatch()

  const CreateQuizDetail = useSelector(state => state.CreateQuizDetail)
  const { quiz_id, passage } = CreateQuizDetail


  const [viewMode, setViewMode] = useState("view")

  const [updateTitle, setUpdateTitle] = useState(false)
  const [updatePara, setUpdatePara] = useState(-1)

  const [qtext, setQText] = useState("")

  const toggleViewMode = () => {
    if(viewMode == 'view'){
        setViewMode('edit')
    }else{
        setViewMode('view')
    }
  }


  useEffect(() => {

      

  }, []);


  const updatePassageRequest = () => {
    dispatch(updatePassage(quiz_id, 'title', qtext, -1, ''))
    setViewMode('view')
  }


  
  return (

    <>
    
    <Card style={{padding:'20px', borderRadius:'20px', borderColor:'#a516f2', marginBottom:'20px'}}>


        {/* <Button onClick={()=>toggleViewMode()}>Edit</Button>   */}


        {passage && passage.title && (
            <>
                <p className='h4'>{passage.title} <Badge style={{border:'solid'}}>Edit</Badge></p>
            
            </>
        )}

        {passage.paras && passage.paras.map((cpara, index)=>(

            <>

                <PassageParaEdit quiz_id={quiz_id} index={index} para={cpara}/>
            
            </>
            
        ))}


        {/* {viewMode == 'view' ? (
            <>

                 
                {passage && passage.title && (
                    <Card style={{border:'None'}}>
                        <Card.Header>
                            <p className='h4'>{passage.title}</p>
                        </Card.Header>
                        <Card.Body>
                            {passage.paras && passage.paras.map((cpara, index)=>(
                                <p className='h5'>
                                    {cpara}
                                </p>
                            ))}
                        </Card.Body>
                    </Card>
                )}

            
            
            </>
        ): (
            <>


                <Form.Control type='text' placeholder='Question' value={qtext} onChange={(e) => setQText(e.target.value)}> 
                </Form.Control>




                <Button onClick={()=>updateInstructionsRequest()}>Update</Button>
            </>
            
        )} */}



        


    </Card>


    
    </>

  );
};

export default CreatorPassageCard;
