import React , {useState, useEffect, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Container, Card, Form, Button, ListGroup, OverlayTrigger, Popover, Tooltip, Carousel, InputGroup, FormControl, Table, Radio, Badge } from 'react-bootstrap'


import VideoComponent from '../VideoComponent'


import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile,
    deviceDetect
  } from "react-device-detect";

export const LandingMobile = ({  }) => {


  const dispatch = useDispatch()

 

  useEffect(() => {

      

  }, []);


  

  
  return (

    <>
            <div style={{marginTop:'0px'}}>

                <section style={{backgroundColor:'#f0f2f2'}}>

                    <div style={{textAlign:'center', marginTop:'0px', padding:'10px'}} >

                        <p className='h2'>Learning English : Made Easy and Fun</p>
                        <p className='h4'>A great resource to prepare Engish for Govt Competitive Exams</p>
                
                        <Link to='/quiz'>
                        <Button style={{width:'25vw', borderRadius:'20px', backgroundColor:'#bb5ced'}}>Click Here to start Quizzes</Button>
                        </Link>
                        
                
                
                    </div>

                </section>



                <section class="ai-model" >

           
                        <p className='h3'>Get started with Simple English Quizzes on basic topics</p>
                        <p className='h5'>Make learning fun for your students.</p>

                    
                        {isMobile ? (
                            <img src="SimpleQuizzes.jpg" alt="Word Usage" style={{width:'90vw'}}/>
                            ) : (
                                <img src="SimpleQuizzes.jpg" alt="Word Usage" style={{height:'50vh'}}/>
                            )}
                 
                </section>

                <section class="ai-model" style={{backgroundColor:'#f0f2f2'}} >

                        <p className='h3'>Practice the basics of Grammar</p>
                        <p className='h5'>Learn about nouns, pronouns ,adjectives, verbs and more.</p>
                    
                  
                        
                        {isMobile ? (
                        <img src="Grammar.jpg" alt="Grammar Quizzes" style={{width:'90vw'}}/>
                    ) : (
                        <img src="Grammar.jpg" alt="Grammar Quizzes" style={{height:'50vh'}}/>
                    )}
                  
                     
                    
                

                </section>

                <section class="ai-model" >

                <p className='h3'>Practice Comprehension Passages till you master how to solve them</p>
                        <p className='h5'>A number of exam like passages are shown.</p>
                    

        
                        {isMobile ? (
                        <img src="Comprehension.jpg" alt="Comprehension Example"  style={{width:'90vw'}}/>
                    ) : (
                        <img src="Comprehension.jpg" alt="Comprehension Example"  style={{height:'50vh'}}/>
                    )}
                        
                
                    
                    

                </section>





                <section class="ai-model" style={{backgroundColor:'#f0f2f2'}}>
                
                <p className='h3'>Try out hundreds of Competitive Exam practice questions.</p>

                    <p className='h5'>Sentence Improvement, error detection, fillers, cloze test and more.</p>

                
       
                        {isMobile ? (
                        <img src="Competitive.jpg" alt="Competitive Exam Practice"  style={{width:'90vw'}}/>
                        ) : (
                            <img src="Competitive.jpg" alt="Competitive Exam Practice"  style={{height:'50vh'}}/>
                        )}



                </section>



                <section class="ai-model" >

        <p className='h3'>Checkout Our interactive Smart Doubt tool.</p>
        <p className='h5'>Pick the topic you want to clarify and keep the learning going.</p>
    
        <Link to='/smartdoubts'>
                            <Button style={{width:'25vw', borderRadius:'20px', backgroundColor:'#bb5ced'}}>Clear Doubts with Smart Doubt</Button>
                            </Link>


        {isMobile ? (
        <img src="SmartDoubt.jpg" alt="Smart Doubt Tool"  style={{width:'90vw'}}/>
    ) : (
        <img src="SmartDoubt.jpg" alt="Smart Doubt Tool"  style={{height:'50vh'}}/>
    )}
        

    
    

</section>




                <section class="ai-model" style={{backgroundColor:'#f0f2f2'}}>
                
                <p className='h3'>Use Our Learning Sessions to get a more guided learning experience.</p>

                    <p className='h5'>A quick read on the basic concepts and new developments in a fun manner.</p>

                
       
                        {isMobile ? (
                        <img src="LearnSess.jpg" alt="Learning Sessions"  style={{width:'90vw'}}/>
                        ) : (
                            <img src="LearnSess.jpg" alt="Learning Sessions"  style={{height:'50vh'}}/>
                        )}



                </section>




                <section class="ai-model" style={{backgroundColor:'white'}}>


                <p className='h3'>Qwz4U on Youtube</p>

                    

                    <p className='h5'>Checkout our youtube channel for fun learning videos.</p>

            
                        <Card style={{margin:'10px', padding:'5px', border:'None'}}>
                                        <Card.Header style={{border:'None', margin:'3px', borderRadius:'10px'}}>

                                <ListGroup horizontal style={{overflow:'scroll'}}>
                                    <ListGroup.Item>
                                        <VideoComponent videoId='mY__YYyu7Ko'  mode='thumbnail'/>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <VideoComponent videoId='OBt0q8kNbD4'  mode='thumbnail'/>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <VideoComponent videoId='aGihOWAmI9M'  mode='thumbnail'/>
                                    </ListGroup.Item>
                                

                                

                                    
                                </ListGroup>
                            </Card.Header>
                            </Card>
                   


                </section>






                <section class="ai-model" style={{backgroundColor:'#f0f2f2'}}>



                        <p className='h3' style={{textAlign:'center'}}>FAQs</p>

                    
                    
                        <ListGroup>
                        <ListGroup.Item style={{border:'None'}}>
                                <Card style={{border:'None'}}>
                                    <Card.Header style={{borderRadius:'20px', borderColor:'#b861fb', margin:'5px'}}>
                                        <p className='h3'>Why is Qwz4U so test centric ?</p>
                                    </Card.Header>
                                        <p className='h5' style={{margin:'20px'}}> We believe that tests are a great way to learn and to evaluate understanding of a topic. Frequent testing reduces the fear of tests which will make it easier for students to clear their exams.</p>
                                </Card>
                            </ListGroup.Item>

                            <ListGroup.Item style={{border:'None'}}>
                                <Card style={{border:'None'}}>
                                    <Card.Header style={{borderRadius:'20px', borderColor:'#b861fb', margin:'5px'}}>
                                        <p className='h3'>What subjects does Qwz4U have tests on ?</p>
                                    </Card.Header>
                                        <p className='h5' style={{margin:'20px'}}> Qwz4U is currently focussed on English topics. This is because we are aiming to primarily help students preparing for competitive exams like the Banking Exam, SSC.</p>
                                </Card>
                            </ListGroup.Item>

                            <ListGroup.Item style={{border:'None'}}>
                                <Card style={{border:'None'}}>
                                    <Card.Header style={{borderRadius:'20px', borderColor:'#b861fb', margin:'5px'}}>
                                        <p className='h3'>How much do tests and courses on Qwz4U cost?</p>
                                    </Card.Header>
                                        <p className='h5' style={{margin:'20px'}}> Currently all our courses and tests are free. There is also no sign up or login. </p>
                                </Card>
                            </ListGroup.Item>


                            <ListGroup.Item style={{border:'None'}}>
                                <Card style={{border:'None'}}>
                                    <Card.Header style={{borderRadius:'20px', borderColor:'#b861fb', margin:'5px'}}>
                                        <p className='h3'>Does Qwz4U have paid content?</p>
                                    </Card.Header>
                                        <p className='h5' style={{margin:'20px'}}> Not at the moment. We might bring in paid courses in the future.</p>
                                </Card>
                            </ListGroup.Item>

                            <ListGroup.Item style={{border:'None'}}>
                                <Card style={{border:'None'}}>
                                    <Card.Header style={{borderRadius:'20px', borderColor:'#b861fb', margin:'5px'}}>
                                        <p className='h3'>Is there a Qwz4U app?</p>
                                    </Card.Header>
                                        <p className='h5' style={{margin:'20px'}}> A Qwz4U app is currently under development and will be released soon.</p>
                                </Card>
                            </ListGroup.Item>


                        </ListGroup>



                
                

                </section>







                </div>


 

    
    </>

  );
};

export default LandingMobile;
