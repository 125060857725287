import React , {useState, useEffect, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Container, Card, Form, Button, ListGroup, OverlayTrigger, Popover, Tooltip, Carousel, InputGroup, FormControl, Table, Radio, Badge } from 'react-bootstrap'

import CreatorQuestionViewCard from './CreatorQuestionViewCard'
import UpdateQuestionCard from './UpdateQuestionCard'

import {checkSolution} from '../../actions/quizActions'

import {deleteCreatorQuestion} from '../../actions/creatorActions'

export const QuestionEditCard = ({ quiz_id, selectedQuestion }) => {


  const dispatch = useDispatch()


  const [viewMode, setViewMode] = useState("view")



  useEffect(() => {

      

  }, [selectedQuestion._id]);


  const deleteQuestionRequest = () => {
    dispatch(deleteCreatorQuestion(quiz_id, selectedQuestion._id))
  }


  
  return (

    <>
    
    <Card style={{padding:'20px', borderRadius:'20px', borderColor:'#a516f2', marginBottom:'20px'}}>

    {selectedQuestion && (
    <>
        <InputGroup>
            <Button onClick={()=>setViewMode('view')}>View</Button>
            <Button onClick={()=>setViewMode('edit')}>Edit</Button>
            <Button onClick={()=>deleteQuestionRequest()}>Delete</Button>
        </InputGroup>



        {viewMode == 'view' && (
            <CreatorQuestionViewCard selectedQuestion={selectedQuestion}/>

        )}


        {viewMode == 'edit' && (
            <UpdateQuestionCard quiz_id={quiz_id} question_id={selectedQuestion._id} question_in={selectedQuestion}/>

        )}


    
    </>
    )}



    </Card>


    
    </>

  );
};

export default QuestionEditCard;
