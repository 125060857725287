import React, { Fragment, useEffect } from 'react' 
import {  useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { LinkContainer } from 'react-router-bootstrap'
import {Navbar, Nav, Container, NavDropdown, Img, Button, DropdownButton, Dropdown } from 'react-bootstrap'



const Header = () => {

    const dispatch = useDispatch()
    const history = useHistory();

    const userR = useSelector(state => state.userR)
    const { user } = userR

    

    useEffect(() => {


        

    }, [])


    return (
        <header className="header">

            <Navbar expand="lg" collapseOnSelect className="gradient-background" style={{padding:'0px'}}>
                
            {/* <Navbar bg="dark"  variant='dark' expand="lg" collapseOnSelect> */}
                
                <Container>

                <LinkContainer to='/'>
                    
                    <img className='tylogo'
                        src="Qwzlogo.png"
                        //width="200"
                        height="60"
                        className="d-inline-block align-top"
                        alt="Qwz4U logo"
                        backgroundColor="white"
                        borderRadius='5px'
                    />
                    
                   
                    
                </LinkContainer>
                <LinkContainer to='/' style={{marginLeft:'20px'}}>
                        <>
                        <Navbar.Brand style={{color:'white'}}>| Qwz4U</Navbar.Brand>
                        </>

                </LinkContainer>


                <Navbar.Toggle aria-controls="basic-navbar-nav" />

                <Navbar.Collapse id="basic-navbar-nav">


                <Nav className="ml-auto">

                <LinkContainer to='/'>
                    <Nav.Link className='navbarlink'><Button style={{ margin:'1px', paddingTop:'5px', paddingBottom:'5px', borderRadius:'20px', backgroundColor:'white', color:'black'}}><i class="fa fa-home"></i></Button></Nav.Link>
                </LinkContainer>


                <LinkContainer to='/quiz'>
                    <Nav.Link className='navbarlink'><Button style={{ margin:'1px', paddingTop:'5px', paddingBottom:'5px', borderRadius:'20px', backgroundColor:'white', color:'black'}}>Quizzes</Button></Nav.Link>
                </LinkContainer>

                <LinkContainer to='/smartdoubts'>
                    <Nav.Link className='navbarlink'><Button style={{ margin:'1px', paddingTop:'5px', paddingBottom:'5px', borderRadius:'20px', backgroundColor:'white', color:'black'}}>SmartDoubts</Button></Nav.Link>
                </LinkContainer>

                <LinkContainer to='/sessions'>
                    <Nav.Link className='navbarlink'><Button style={{ margin:'1px', paddingTop:'5px', paddingBottom:'5px', borderRadius:'20px', backgroundColor:'white', color:'black'}}>Sessions</Button></Nav.Link>
                </LinkContainer>
                
                    {user && user.token && (
                        <LinkContainer to='/admin3323/logout'>
                        <Nav.Link className='navbarlink'><Button style={{ margin:'1px', paddingTop:'5px', paddingBottom:'5px', borderRadius:'20px', backgroundColor:'white', color:'black'}}>Logout</Button></Nav.Link>
                    </LinkContainer>
                    )}

                </Nav>
            </Navbar.Collapse>
        </Container>
        </Navbar>
        </header>
    )
}

export default Header
