import React , {useState, useEffect, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Container, Card, Form, Button, ListGroup, OverlayTrigger, Popover, Tooltip, Carousel, InputGroup, FormControl, Table, Radio, Badge } from 'react-bootstrap'


import {checkSolution} from '../../actions/quizActions'

export const QuestionCard = ({ selectedQuestion }) => {


  const dispatch = useDispatch()

  const [viewInstructions, setViewInstructions] = useState(false)
  
   const [selectedOption, setSelectedOption] = useState(-1)



  useEffect(() => {

    setSelectedOption(-1)    

  }, [selectedQuestion._id]);


  const submitSolutionHandler = () => {
        if(!selectedQuestion.isAnswered){
            dispatch(checkSolution(selectedQuestion._id, selectedOption))
        }

   }

   const updateSelectedOption = (option) => {
        if(!selectedQuestion.isAnswered){
            setSelectedOption(option)
        }
   }



  
  return (

    <>
    
    <Card style={{padding:'20px', borderRadius:'20px', borderColor:'#a516f2', marginBottom:'20px'}}>

    {selectedQuestion && (
    <>

        <p className='h5'>{selectedQuestion.instruction}</p>

        <Card style={{margin:'10px', borderRadius:'5px'}}>
            <Card.Header style={{borderRadius:'5px'}}>
                <p className='h3'>{selectedQuestion.question}</p>

            </Card.Header>

        </Card>



        {selectedQuestion.options && selectedQuestion.options.map((coption, index)=>(

            <Card style={{margin:'10px', borderRadius:'20px', backgroundColor:selectedOption == index ? '#d4c068': 'white'}} onClick={()=>updateSelectedOption(index)}>
                <Card.Header style={{borderRadius:'20px'}}>


                    <p className='h4'>{coption}</p>


                </Card.Header>

            </Card>

        ))}

    
    </>
)}


    <div  style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
    <Button style={{width:'25vw', borderRadius:'20px', backgroundColor:'#bb5ced'}} onClick={()=>submitSolutionHandler()}>
        Submit
    </Button>
    </div>

    {selectedQuestion.isAnswered && selectedQuestion.isAnswered == true && (
        <Card style={{border:'None', padding:'20px'}}>
        {selectedQuestion.correct && (
            <Badge style={{padding:'5px', borderRadius:'10px', backgroundColor:selectedQuestion.correct == 'Correct' ? '#b0ed6f' : '#d4615b'}}>

                <p className='h3'>{selectedQuestion.correct}</p>
            </Badge>

        )}
        
        </Card>
        
    )}

</Card>


    
    </>

  );
};

export default QuestionCard;
