import React , {useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Container, Card, Form, Button, ListGroup, OverlayTrigger, Popover, Tooltip, Carousel, InputGroup, FormControl, Table, Badge, Dropdown } from 'react-bootstrap'
import Message from '../components/Message'
import Loader from '../components/Loader'
import Meta from '../components/Meta'

import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile,
    deviceDetect
  } from "react-device-detect";



export const QuizScreen = ({match, history}) => {


  
    const dispatch = useDispatch()

  


    return (

        <>

              <Card>

                    <Card.Header>

                        <p className='h1' style={{textAlign:'center'}}> Coming Soon</p>

                        <p className='h3' style={{textAlign:'center'}}>Learning Sessions to give students a guided learning experience.</p>


                    </Card.Header>

              </Card>

        </>
    )
}


export default QuizScreen



