import axios from 'axios';
import {setAlert} from './alertActions';
import {

    Qs_LIST,
    Qs_FAIL,
    Qs_CLEAR,
    Qs_TAKE,
    Qs_ADD,
    Qs_UPDATE,
    Qs_DELETE,
    Qs_SOLUTION_DELETE,
    Qs_SOLUTION_ADD,
    Qs_LEARNING_ADD,
    Qs_LEARNING_DELETE,
    Qs_POST,

    Qs_ANALYTICS,
    Qs_ANALYTICS_FAIL,
    Qs_ANALYTICS_CLEAR,

    Qs_SUBMIT_ANSWER,
    Qs_SUBMIT_ANSWER_SL,

    Qs_SOLUTION_BYID,
    Qs_SUBMIT_ANSWER_TEST


} from '../constants/qsConstants'




//Load puzzles
export const answerQs = (q_id, answer, hashtag, mode, card_id) => async (dispatch, getState) => {

    try{
        // dispatch({ type: MCQ_REQUEST })

        // const { userLogin: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                //Authorization: `Bearer ${userInfo.token}`
            }
        }


        const full_url = `/api/qs/respond/${q_id}`

        const { data } = await axios.post(full_url, {answer}, config)


        console.log('respond to Qs : 1 ',card_id, q_id, data, hashtag, mode)


        if(mode && mode == 'SL'){
            dispatch({
                type: Qs_SUBMIT_ANSWER_SL,
                payload: {card_id:card_id, _id:q_id, hashtag:hashtag,  answer:answer, correct:data.eval, learn:data.learn}
            })
        }else{
            dispatch({
                type: Qs_SUBMIT_ANSWER,
                payload: {card_id:card_id,  _id:q_id, answer:answer, correct:data.eval, learn:data.learn}
            })
        }


 

        

    }catch (error){

        dispatch({
            type: Qs_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })

    }

}




//Load puzzles
export const answerQTest = (q_id, answer, hashtag, mode, card_id) => async (dispatch, getState) => {

    try{
        // dispatch({ type: MCQ_REQUEST })

        // const { userLogin: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                //Authorization: `Bearer ${userInfo.token}`
            }
        }


        const full_url = `/api/qs/respond/${q_id}`

        const { data } = await axios.post(full_url, {answer}, config)


        console.log('respond to Qs 2 : ', q_id, data, hashtag, mode)


        dispatch({
            type: Qs_SUBMIT_ANSWER_TEST,
            payload: {card_id:card_id, question_id:q_id, hashtag:hashtag,  answer:answer, correct:data.eval, learn:data.learn}
        })


 

        

    }catch (error){

        dispatch({
            type: Qs_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })

    }

}
