import React , {useState, useEffect, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Container, Card, Form, Button, ListGroup, OverlayTrigger, Popover, Tooltip, Carousel, InputGroup, FormControl, Table, Radio, Badge } from 'react-bootstrap'


export const ConceptCard = ({ concept}) => {


  const dispatch = useDispatch()

  const [viewShare, setViewShare] = useState(false)



  useEffect(() => {



  }, []);


  const submitHandler = (e) => {
    e.preventDefault()
    
    
   }



  
  return (

    <Card style={{padding:'0px', borderRadius:'20px', borderColor:'#a516f2', marginBottom:'20px'}}>

        
        <Card.Header style={{borderRadius:'20px'}}>
            <ul>
            {concept.items && concept.items.map((citem, index)=>(

                <li className='h5'>{citem}</li>

                ))}
            </ul>


        </Card.Header>

        {/* <p className='h1' style={{textAlign:'center'}}>{concept.title}</p> */}








    </Card>


  );
};

export default ConceptCard;
