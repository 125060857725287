import React , {useState, useEffect, Fragment } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Container, Card, Form, Button, ListGroup, OverlayTrigger, Popover, Tooltip, Carousel, InputGroup, FormControl, Table, Radio, Badge } from 'react-bootstrap'



export const QuizCompleted = ({ attempted, score, nquestions, nextQuiz}) => {


  const dispatch = useDispatch()
  let history = useHistory();

  const [viewShare, setViewShare] = useState(false)

  const toggleViewShare = (e) =>{
    e.preventDefault()

    setViewShare(viewShare => viewShare =! viewShare)

  }




  useEffect(() => {



  }, []);


  const submitHandler = (e) => {
    e.preventDefault()
    
    
   }

   const nextQuizRequest = (quiz_url) => {
      history.push(`/${quiz_url}`)
   }



  
  return (

    <Card style={{padding:'20px', borderRadius:'20px', borderColor:'#a516f2', marginBottom:'20px'}}>

        
        <p className='h1' style={{textAlign:'center'}}>Congrats on completing this Quiz</p>

        <p className='h3' style={{textAlign:'center'}}>Your Score {score}/{attempted}</p>

      {nextQuiz && (
        <>
        
        <p className='h4' style={{textAlign:'center'}}><span>Now try another quiz on the topic : </span><Badge style={{backgroundColor:'#bb5ced', padding:'10px', borderRadius:'10px', color:'white'}} onClick={()=>nextQuizRequest(nextQuiz.url)}><span className='h3' style={{color:'white'}}>{nextQuiz.title}</span></Badge></p>
        </>
      )}


        
        {/* <Row style={{marginTop:'10px'}}>
          <Col>
          <Card.Header>

          <p className='h4'>Try another quiz on the same topic</p>
          </Card.Header>

          </Col>
          <Col>
          <Card.Header>

          <p className='h4'>Try the next quiz on </p>
          </Card.Header>

          </Col>
        </Row> */}




    </Card>


  );
};

export default QuizCompleted;
