import React , {useState, useEffect,  Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Row, Col, Card, Button, Form,  OverlayTrigger, Tooltip, InputGroup, ListGroup, Badge } from 'react-bootstrap'

import Moment from 'react-moment';
import DateFormatted from '../DateFormatted'



import QTestItemTake from './Qs/QTestItemTake'



export const SLTest = ({questions, card_id}) => {

    const dispatch = useDispatch()


    const [tagC, setTagC ] = useState('')

    const [textQuery, setTextQuery ] = useState('')

    const [addTagtoggle, setaddTagtoggle ] = useState(false)
    const toggleAddTag = () => {
        setaddTagtoggle(addTagtoggle => !addTagtoggle)
    }



    useEffect(() => {
        
    }, [])




    return (

        <Card style={{margin:'10px', padding:'5px', border:'None'}}>
 
            {questions && questions.map((question, index)=>(
                <QTestItemTake question={question} index={0} mode_in='SL' card_id={card_id}/>
            ))}

        </Card>
    )
}


export default SLTest
